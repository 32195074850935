<template>
  <div>
    <template v-if="config.public.site.identifier == 'mm_dk'">
      <div
        class="bg-black mx-auto mb-3 h-4 w-16 rounded"
        style="background-color: rgba(81, 91, 67, 0.2)"
      >
        <div class="bg-blue relative h-full w-6 rounded-l animate-loading" />
      </div>
    </template>
    <template v-else>
      <div class="bg-blue mx-auto mb-3 h-4 w-16 rounded">
        <div class="bg-red relative h-full w-6 rounded-l animate-loading" />
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
const config = useRuntimeConfig()
</script>
